<template>
	<div class="requests">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back pencil />
		</div><!-- header -->
		<div class="header-image green"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="SendIcon"
				title="All Requests"
				colour="green"
			/>
            <!-- Requested -->
            <div class="section-gap section-gap-extra">
                <div class="section-title-wrap">
                    <div class="icon-split">
                        <h2 class="section-title">Requested</h2>
                        <span>{{ requested.count }}</span>
                    </div>
                </div>
                <div class="request-card-wrap">
                    <RequestCard
                        v-for="request in requested.requests"
                        :key="request.id"
                        :id="request.id"
                        :category="request.category"
                        :title="request.category"
                        :content="request.messages.length ? request.messages[0].content : request.message"
                        :location="request.port"
                        :messageCount="request.messages.length"
                        :time="getTimeAgo(request.updated_at)"
                        unread
                        routeName="RequestsSingle"
                    />
                </div>
            </div>
            <!-- Accepted -->
            <div class="section-gap section-gap-extra">
                <div class="section-title-wrap">
                    <div class="icon-split">
                        <h2 class="section-title">Accepted</h2>
                        <span>{{ accepted.count }}</span>
                    </div>
                </div>
                <div class="request-card-wrap">
                    <RequestCard
                        v-for="request in accepted.requests"
                        :key="request.id"
                        :id="request.id"
                        :category="request.category"
                        :title="request.category"
                        :content="request.messages.length ? request.messages[0].content : request.message"
                        :location="request.port"
                        :messageCount="request.messages.length"
                        :time="getTimeAgo(request.updated_at)"
                        unread
                        routeName="RequestsSingle"
                    />
                </div>
            </div>
            <!-- In Progress -->
            <div class="section-gap section-gap-extra">
                <div class="section-title-wrap">
                    <div class="icon-split">
                        <h2 class="section-title">In Progress</h2>
                        <span>{{ inProgress.count }}</span>
                    </div>
                </div>
                <div class="request-card-wrap">
                    <RequestCard
                        v-for="request in inProgress.requests"
                        :key="request.id"
                        :id="request.id"
                        :category="request.category"
                        :title="request.category"
                        :content="request.messages.length ? request.messages[0].content : request.message"
                        :location="request.port"
                        :messageCount="request.messages.length"
                        :time="getTimeAgo(request.updated_at)"
                        unread
                        routeName="RequestsSingle"
                    />
                </div>
            </div>
            <!-- Completed -->
            <div class="section-gap section-gap-extra">
                <div class="section-title-wrap">
                    <div class="icon-split">
                        <h2 class="section-title">Completed</h2>
                        <span>{{ completed.count }}</span>
                    </div>
                </div>
                <div class="request-card-wrap">
                    <RequestCard
                        v-for="request in completed.requests"
                        :key="request.id"
                        :id="request.id"
                        :category="request.category"
                        :title="request.category"
                        :content="request.messages.length ? request.messages[0].content : request.message"
                        :location="request.port"
                        :messageCount="request.messages.length"
                        :time="getTimeAgo(request.updated_at)"
                        unread
                        routeName="RequestsSingle"
                    />
                </div>
            </div>
            <!-- Closed -->
            <div class="section-gap section-gap-extra">
                <div class="section-title-wrap">
                    <div class="icon-split">
                        <h2 class="section-title">Closed</h2>
                        <span>{{ closed.count }}</span>
                    </div>
                </div>
                <div class="request-card-wrap">
                    <RequestCard
                        v-for="request in closed.requests"
                        :key="request.id"
                        :id="request.id"
                        :category="request.category"
                        :title="request.category"
                        :content="request.messages.length ? request.messages[0].content : request.message"
                        :location="request.port"
                        :messageCount="request.messages.length"
                        :time="getTimeAgo(request.updated_at)"
                        unread
                        routeName="RequestsSingle"
                    />
                </div>
            </div>
        </div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import RequestCard from '@/components/RequestCard.vue';
    import UserService from '@/services/UserService';
    import moment from 'moment';
	export default {
		name: 'RequestsAll',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			RequestCard
		},
		data () {
	        return {
                requested: {},
                accepted: {},
                inProgress: {},
                completed: {},
                closed: {}
	        };
	    },
	    methods: {
            getRequests () {
                UserService.getAllRequests().then((response) => {
                    this.requested = response.data.requested;
                    this.accepted = response.data.accepted;
                    this.inProgress = response.data.in_progress;
                    this.completed = response.data.completed;
                    this.closed = response.data.closed;
                });
            },
            getTimeAgo (date) {
                return moment(date).fromNow();
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getRequests();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
</style>
